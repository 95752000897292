.quiz-summary {
  & .slide-1 {
    position: relative;
    padding-bottom: 100px;

    & .container {
      padding-top: 30px;

      & .row {
        display: flex;
        flex-direction: row;


        & .left-side {
          width: 100%;
          flex-grow: 1;
          margin-right: 23px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        .right-side {
          min-width: 560px;
          width: 560px;
          margin-left: 23px;

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }


        & p {
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.02em;

          color: #000000;
        }

      }
    }

    & .lbs-body {
      padding: 16px;

      & .lbs-title {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: flex-end;
        padding-top: 10px;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #0B1317;
        mix-blend-mode: normal;

        & .lbs-eq-0, & .lbs-eq-6 {
          opacity: 0.1;
        }

        & .lbs-eq-1, & .lbs-eq-5 {
          opacity: 0.25;
        }

        & .lbs-eq-2, & .lbs-eq-4 {
          opacity: 0.5;
        }

        & .lbs-eq-3 {
          font-size: 36px;
          line-height: 24px;

          & span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      & .lbs-sub-title {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        /* identical to box height, or 200% */

        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #6E736D;
        margin-bottom: 16px;
      }

      & .lbs-graph {
        position: relative;
        width: 100%;
        height: 210px;

        & img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          max-width: 100%;
          max-height: 100%;
          pointer-events: none;
          margin: 0 auto;
        }

        & .start, & .end {
          position: absolute;
          width: 95px;
          text-align: left;
          & .date {
            font-weight: bold;
            font-size: 11px;
            //line-height: 24px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #6E736D;
          }

          & .lbs-weight {
            font-weight: bold;
            font-size: 22px;
            //line-height: 24px;
            color: #0D160B;

            & span {
              font-size: 16px;
              //line-height: 19px;
            }
          }
        }

        & .start {
          top: 5px;
          left: 35px;
        }

        & .end {
          bottom: 55px;
          right: 0;
        }

        &.revert {
          & .start {
            top: auto;

            bottom: 55px;
          }

          & .end {
            bottom: auto;

            top: 5px;
          }
        }
      }
    }
  }


  & h1 {
    font-weight: 800;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0.02em;

    & span {
      color: #E8532C;
    }
  }

  & .graph {
    background: #FFFFFF;
    border: 2px solid #E0E2E4;
    border-radius: 5px;

    & .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;

      color: rgba(13, 22, 11, 0.6);

      height: 58px;

      border-bottom: 1px solid #E0E2E4;

      display: flex;
      align-items: center;

      &.calendar {
        justify-content: center;
      }

      & .day {
        padding: 6.5px 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .day-title {
          font-weight: bold;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #6E736D;
        }

        & .day-number {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          text-align: center;

          color: #0D160B;
        }

        & .dot {
          width: 6px;
          height: 6px;
          background: #C2C4C5;
          border-radius: 6px;
          display: inline-block;
          margin-top: 3px;
        }

        &:last-child {
          & .day-title, & .day-number {
            color: #E8532C;
          }

          & .dot {
            background: #E8532C;
          }
        }
      }
    }

    &.big {
      min-width: 560px;
      width: 560px;
      height: 338px;
    }

    &.small {
      width: 267px;
      height: 200px;
      margin-top: 26px;

      padding: 0 16px;
    }

    &.percent-50 {
      width: 100%;
      flex-grow: 1;
      min-height: 50px;
      margin: 13px 6px;

      padding: 0 16px;
      height: 220px;

      &.h-280 {
        height: 280px;
      }
    }

    & .heart {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 140px;

      & span {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        position: absolute;
        margin: -12px auto 0;
      }
    }

    & .weight {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 140px;

      & div {
        font-weight: bold;
        font-size: 46px;
        line-height: 24px;
        color: #E8532C;

        & span {
          font-size: 18px;
        }
      }

      & p {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #0D160B;
      }
    }
  }

  & .slide-2 {
    background: #E9F9FB;
    min-height: 886px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .daily-calorie {

    & .graph-row {
      display: flex;
      margin-bottom: 30px;
      align-items: center;

      & label {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */

        letter-spacing: 1px;

        color: #6E736D;
        width: 120px;
        text-align: left;
        margin-right: 10px;
      }

      & .graph-line {
        background: #E7E7E8;
        border-radius: 70px;
        position: relative;
        flex-grow: 1;
        height: 10px;

        & .active-line {
          background: #E8532C;
          border-radius: 70px;

          position: absolute;
          top: 0;
          left: calc(50% - 28px);
          width: 136px;
          height: 10px;
        }

        & .text {
          position: absolute;
          top: -23px;
          left: calc(50% - 28px + 18px);

          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */

          text-align: center;
          letter-spacing: 1px;

          color: #0D160B;


        }
      }

      &:first-child {
        margin-top: 55px;
      }

      &:last-child {
        & .active-line {
          background: #26C6DA;
          left: calc(50% - 68px);
        }
        & .text {
          left: calc(50% - 68px + 18px);
        }
      }
    }

  }

  & .water-balance, & .daily-activity {
    display: flex;
    align-items: center;
    text-align: left;
    height: 160px;

    & svg {
      margin-right: 10px;
    }

    & .data {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #6E736D;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & h2 {
        font-weight: 800;
        font-size: 26px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: #0D160B;

        margin: 10px 0;
        padding: 0;
      }
    }
  }

  & .body-index {
    text-align: left;

    & .number {
      font-style: normal;
      font-weight: 800;
      font-size: 42px;
      line-height: 52px;
      letter-spacing: 0.02em;
      color: #E8532C;

      margin-top: 32px;
      margin-bottom: 30px;
    }

    & .graph-line {
      background: #E7E7E8;
      border-radius: 70px;
      height: 10px;
      width: 100%;
      position: relative;

      & .active-line {
        background: #E8532C;
        border-radius: 70px;

        position: absolute;
        top: 0;
        left: calc(50% - 68px);
        width: 136px;
        height: 10px;
      }

      & .middle {
        background: #E8532C;
        border: 2px solid #FFFFFF;
        border-radius: 10px;

        position: absolute;
        top: -8px;
        left: 50%;

        width: 7px;
        height: 26px;
      }

      & .my-marker {
        background: #26C6DA;
        border: 2px solid #FFFFFF;
        border-radius: 10px;

        position: absolute;
        top: -10px;
        left: 250px;

        width: 15px;
        height: 30px;

        &-v0 {
          left: calc(50% - 68px);
        }
        &-v1 {
          left: calc(50%);
        }
        &-v2 {
          left: calc(50% + 35px);
        }
        &-v3 {
          left: calc(50% + 55px);
        }
        &-v4 {
          left: calc(50% + 85px);
        }
        &-v5 {
          left: calc(50% + 105px);
        }
      }

      & .my-text {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #26C6DA;

        position: absolute;
        left: 245px;
        top: -30px;

        &-v0 {
          left: calc(50% - 68px - 5px);
        }
        &-v1 {
          left: calc(50% - 5px);
        }
        &-v2 {
          left: calc(50% + 35px - 5px);
        }
        &-v3 {
          left: calc(50% + 55px - 5px);
        }
        &-v4 {
          left: calc(50% + 85px - 5px);
        }
        &-v5 {
          left: calc(50% + 105px - 5px);
        }
      }
    }

    & .description {
      margin-top: 15px;
      display: flex;

      & span {
        width: 100%;

        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: #6E736D;

        &.active {
          color: #E8532C;
        }
      }


    }
  }

  & button.btn-next {
    width: 240px;
  }
}

.slide-3 {
  padding: 80px 0;

  & h1 {
    font-weight: bold;
    font-size: 42px;
    line-height: 56px;
    /* or 133% */

    letter-spacing: 0.02em;

    color: #0D160B;
    margin: 0;
    padding: 0;

    max-width: 550px;
  }

  & .plan-btn {
    margin-bottom: 50px;

    text-align: center;

    & button {
      width: 240px;
    }
  }

  & .img-row {
    margin-bottom: 50px;
    text-align: center;
  }

  & .text-row {
    display: flex;
    margin-bottom: 50px;

    & .text-block {
      margin: 0 50px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      & .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #0D160B;
      }

      & .description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #0D160B;
      }
    }
  }

  & .alert {
    padding: 25px;

    background: #F0EAF8;
    border-radius: 10px;

    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #6F2DBD;
  }


}

