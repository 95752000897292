.hide-mobile {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .absolute-mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    z-index: 20;
    padding: 0 20px;
  }
  .hide-mobile {
    display: block;
  }
  .header {
    padding-right: 16px;
    padding-left: 10px;
    height: 56px;

    & .logo {
      & svg {
        width: 36px;
      }
    }
  }
  .container, .quiz-container .container {
    width: 100vw;
    max-width: 100vw;
  }

  .bg-overlay {
    display: none;
  }

  .quiz-container .container {
    padding: 16px;

    & h1 {
      font-size: 28px;
      line-height: 36px;
      //text-align: left;
    }
  }

  .form-content {
    width: 100%;

    &.lg {
      width: 100%;
    }
  }

  .flex-wrap {
    & .condition {
      width: 100%;
    }
  }

  .quiz-summary {

    & h1 {
      font-size: 26px;
      line-height: 34px;
    }

    & .slide-1 {
      & .container {
        padding: 16px;

        & .row {
          flex-wrap: wrap;

          & p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      & .logo svg {
        width: 116px;
      }

      & .btn-next {
        display: none;
      }


      & .lbs-body .lbs-graph {
        height: 145px;
      }


      & .graph.small, & .graph.big, & .container .row .right-side {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }

      & .graph.big {
        height: 268px;
      }

    }

    & .slide-2 {
      padding-bottom: 50px;

      & .row {
        flex-wrap: wrap;
      }

      & .daily-calorie .graph-row {
        flex-wrap: wrap;

        &:first-child {
          margin-top: 30px;
        }

        & label {
          width: 100%;
          margin-bottom: 30px;
        }
      }

      & .water-balance .data h2, & .daily-activity .data h2 {
        font-size: 22px;
      }

      & .water-balance svg {
        margin-right: 28px;
        margin-left: 28px;
      }

      & .daily-activity {
        flex-wrap: wrap;

        & svg {
          margin-right: 20px;
          width: 80px;
        }

        & .data {
          width: 60%;
          margin-right: 0 !important;
        }
      }
    }

    & .slide-3 {
      padding: 45px 0;

      & .text-row {
        flex-wrap: wrap;
        margin-bottom: 10px;

        & .text-block {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          & .title {
            max-width: calc(100% - 40px - 32px - 20px);
          }

          & svg {
            margin-right: 20px;

          }

          & .description {
            margin-top: 14px;
            width: 100%;
          }
        }
      }


      & .img-row img {
        width: 100%;
        max-width: 100%;
      }

      & .container {
        padding: 0 16px;
      }
    }

    & .slide-1 .container .row .left-side {
      margin-right: 0;
    }

    & .slide-1 .container .row .right-side {
      margin-left: 0;
      margin-top: 24px;
    }

    & .bg-overlay-slide1 .top-right {
      width: 75px;
      top: 0;
    }
  }

  .intro-image {
    width: 100% !important;
  }
  .intro-container {
    margin-bottom: 0px !important;
  }
}


.intro-image {
  width: 600px;
}
.intro-container {
  margin-bottom: -100px;
}
