.bg-overlay {
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: calc(100vh - #{$toolbarHeight});
  overflow: hidden;

  & .bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & .top-right {
    position: absolute;
    top: -19px;
    right: -40px;
  }
}

.bg-overlay-slide1 {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  & .bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & .top-right {
    position: absolute;
    top: 40px;
    right: 0;
  }
}

.quiz-container {
  & .container {
    width: 736px;
    padding-top: 100px;
    padding-bottom: 100px;

    & h1 {
      padding: 0;
      margin: 0;
      text-align: center;

      font-weight: 600;
      font-size: 40px;
      line-height: 56px;
    }
  }
}
