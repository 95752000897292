.header {
  height: $toolbarHeight;
  background: #FFFFFF;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  position: relative;

  & .progress {
    background: #26C6DA;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & > .container > .row {
    align-items: center;
    & .back {
      & button {
        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 16px;
      }
      width: 30%;
    }

    & .logo {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }

    & .text-progress {
      width: 30%;
      text-align: right;
      font-size: 14px;
      letter-spacing: 0.03em;

      & span {
        font-weight: 700;
      }
    }
  }
}
