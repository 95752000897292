button {
  background: none;
  border: none;
  cursor: pointer;

  &.btn-next {
    transition: background 1.5s, box-shadow 1.5s;

    width: 100%;
    height: 48px;

    background: #26C6DA;
    box-shadow: 0 8px 20px rgba(38, 198, 218, 0.25);
    border-radius: 7px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;

    color: #FFFFFF;

    margin-top: 16px;

    &[disabled] {
      background: #F6F6F6;
      color: #AFAFAF;
      box-shadow: none;
    }
  }

  &.btn-quiz {
    width: 100%;
    height: 56px;

    font-weight: 600;
    font-size: 16px;

    line-height: 24px;
    letter-spacing: 0.03em;

    display: flex;
    align-items: center;
    text-align: left;

    background: #FFFFFF;
    border: 1px solid rgba(33, 33, 33, 0.16);
    border-radius: 8px;

    margin-top: 8px;
    padding: 16px;

    & span {
      flex-grow: 1;
    }

    &:hover, &.active {
      border: 1px solid #26C6DA;
    }

    &.active-multiple {
      border: 1px solid #26C6DA;
      background: rgba(38, 198, 218, 0.1);
    }
  }
}
