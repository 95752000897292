
.rating-a .rating > *:before, .rating-a .rating > * .fill {
    content: "";
    display: block;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    text-align: left;
    text-indent: -3000em;
}

.rating-a {
    display: block;
    position: relative;
    color: #969696;
    font-size: 16px;
    line-height: 1.3;
}

.rating-a .rating {
    display: block;
    overflow: hidden;
    position: relative;
    height: 18px;
    margin: 0 -1px 0 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background: none;
    text-align: left;
    text-indent: -3000em;
}

.rating-a .rating > * {
    display: block;
    position: relative;
    z-index: 2;
    float: left;
    width: 18px;
    height: 100%;
    margin: 0 6px 0 0;
}

.rating-a .rating > *:before {
    content: "";
    z-index: 1;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PHBhdGggZmlsbD0iI2RkZGRkZCIgZD0iTTE3LjUgNi40TDEyIDUuNiA5LjUuM2MtLjItLjQtLjgtLjQtMSAwTDYgNS42bC01LjUuOGMtLjUuMS0uNy42LS4zIDFsNCA0LjEtMSA1LjhjLS4xLjUuNC44LjguNmw1LTIuNyA0LjkgMi43Yy40LjIuOS0uMS44LS42bC0xLTUuOCA0LTQuMWMuNS0uNC4zLS45LS4yLTF6Ii8+PC9zdmc+) no-repeat;
    background-size: auto 100%;
}

.rating-a .rating > * .fill {
    right: auto;
    z-index: 2;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PHBhdGggZmlsbD0iI2ZmYzEwNyIgZD0iTTE3LjUgNi40TDEyIDUuNiA5LjUuM2MtLjItLjQtLjgtLjQtMSAwTDYgNS42bC01LjUuOGMtLjUuMS0uNy42LS4zIDFsNCA0LjEtMSA1LjhjLS4xLjUuNC44LjguNmw1LTIuNyA0LjkgMi43Yy40LjIuOS0uMS44LS42bC0xLTUuOCA0LTQuMWMuNS0uNC4zLS45LS4yLTF6Ii8+PC9zdmc+) no-repeat;
    background-size: auto 100%;
    width: 15px;
    height: 15px;
}

