.form-content {
  width: 352px;
  margin: 30px auto 0;

  &.lg {
    width: 600px;
  }

  & input.form-control, & .form-select input {
    padding: 16px;
    width: 100%;
    height: 56px;

    border: 1px solid transparent;
    background: rgba(33, 33, 33, 0.04);
    border-radius: 8px;

    font-size: 16px;
    line-height: 24px;


    &:focus, &.active {
      border: 1px solid #26C6DA;
      box-shadow: 0 0 16px rgba(38, 198, 218, 0.16);
      background: #FFF;
    }

    &::placeholder {
      color: $placeholderColor;
    }
  }

  & .form-select input {
    height: 42px;
  }

  & .form-toggle {
    display: flex;
    width: 100%;
    height: 48px;

    background: rgba(33, 33, 33, 0.08);
    border-radius: 8px;
    margin-bottom: 16px;

    padding: 2px;

    & button {
      flex-grow: 1;

      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.03em;

      &.active {
        background: #FFFFFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
      }
    }
  }

}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;

  & .condition {
    margin: 4px;
    display: flex;
    width: 360px;

    cursor: pointer;

    background: #FFFFFF;
    border: 1px solid rgba(33, 33, 33, 0.16);
    box-sizing: border-box;
    border-radius: 8px;

    padding: 16px;
    align-items: center;

    &:hover, &.active {
      border: 1px solid #26C6DA;
    }

    & .data {
      margin-left: 23px;
      flex-grow: 1;

      & .title {
        font-weight: 600;
        font-size: 16px;
        //line-height: 24px;

        & span {
          font-size: 10px;
        }
      }

      & .description {
        font-size: 12px;
        line-height: 18px;
        opacity: 0.64;
        padding-right: 10px;
        margin: 3px 0;
      }

      & .score {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
