.container {
  width: 1024px;
  margin: 0 auto;
}

.row {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

p {
  padding: 0;
  margin: 0;
}





.grid {
  display: grid;

  @for $i from 1 through 6 {
    &.grid-rows-#{$i} {
      grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
    }
  }

  &.grid-flow-row {
    grid-auto-flow: row;
  }
  &.grid-flow-col {
    grid-auto-flow: column;
  }
  &.grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  &.grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{$i * 5}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i * 5}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i * 5}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i * 5}px !important;
  }
  .m-#{$i} {
    margin: #{$i * 5}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i * 5}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i * 5}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i * 5}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i * 5}px !important;
  }
  .p-#{$i} {
    padding: #{$i * 5}px !important;
  }

  .gap-#{$i} {
    gap: #{$i * 5}px !important;
  }
}

@for $i from 1 through 10 {
  .col-f-#{$i * 100} {
    width: #{$i * 100}px !important;
    max-width: #{$i * 100}px !important;
    min-width: #{$i * 100}px !important;
    // flex-basis: auto !important;
  }
  .col-f-#{$i * 100 + 50} {
    width: #{$i * 100 + 50}px !important;
    max-width: #{$i * 100 + 50}px !important;
    min-width: #{$i * 100 + 50}px !important;
    // flex-basis: auto !important;
  }
}

.color-black {
  color: #000;
}

.bg-accent {
  box-shadow: 0 8px 20px rgba(38, 198, 218, 0.15);
  border-radius: 4px;
  font-size: 18px;
  color: #000;
}

.user-review {
  @extend .bg-accent;
  font-size: 16px;
  padding: 20px;
}
