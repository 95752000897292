.calculating {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .square {
    width: 200px;
    height: 200px;
    border-radius: 100px;

    background: #FFFFFF;
    border: 8px solid #EEEEEE;

    display: flex;

    justify-content: center;
    align-items: center;

    font-weight: 800;
    font-size: 42px;
    line-height: 24px;

    color: #222222;
  }

  & .text {
    margin-top: 20px;
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.1em;

    color: #222222;
  }
}
