@import "vars";
@import "layout";
@import "buttons";
@import "quiz/base";
@import "rating";
@import "mobile";

* {
  box-sizing: border-box;
}

*:focus,
*.focus {
  outline: 0;
}

a {
  text-decoration: none;
}

html, body {
  padding: 0;
  margin: 0;
}

body,
a {
  font-family: "Barlow", Sans-serif;
  color: $textColor;
}
